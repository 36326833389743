import React, { Fragment } from 'react'
import Layout from '../layout'
import Arrow from '../components/Arrow'
import { Link } from 'gatsby'
import { language } from '../buildConfig.js'
import { menu, privacy } from '../messages'
export default () => (
    <Layout language={language} page='androidPrivacy'>
        <section>
            <div className='text-content'>
                <h1 className='h2'>{privacy[language].androidTitle}</h1>
                <ol className='body-4 top-level'>
                    {(language === 'fr' && (
                        <Fragment>
                            <li>
                                <div>
                                    Pour activer le verrouillage d’écran,
                                    accédez à la fonctionnalité Épinglage
                                    d’écran (ou Épingler les fenêtres) dans les
                                    paramètres de votre téléphone. L’emplacement
                                    de la fonctionnalité Épinglage
                                    d’écran/Épingler les fenêtres dans les
                                    paramètres varie selon le modèle de
                                    téléphone et la version d’Android. Les
                                    emplacements les plus courants sont les
                                    suivants :
                                    <ul>
                                        <li>
                                            Paramètres <Arrow /> Sécurité
                                        </li>
                                        <li>
                                            Paramètres <Arrow /> Personnel{' '}
                                            <Arrow /> Sécurité
                                        </li>
                                        <li>
                                            Paramètres <Arrow /> Sécurité et
                                            localisation
                                        </li>
                                        <li>
                                            Paramètres <Arrow /> Verrouillage de
                                            l’écran et sécurité
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                Activez la fonctionnalité Épinglage
                                d’écran/Épingler les fenêtres et choisissez
                                l’option qui permet de demander la saisie d’un
                                mot de passe pour annuler l’épinglage.
                            </li>

                            <li>
                                <div>
                                    <strong>Verrouillage d’écran</strong> <br />
                                    Suivez les instructions à l’écran pour
                                    activer la fonctionnalité Épinglage
                                    d’écran/Épingler les fenêtres lorsque vous
                                    affichez votre carte d’assurance automobile.
                                    Votre téléphone sera verrouillé dans votre
                                    portefeuille numérique et empêchera ainsi
                                    l’accès à vos renseignements personnels et à
                                    vos avis, messages et autres applications.
                                </div>
                            </li>
                        </Fragment>
                    )) || (
                        <Fragment>
                            <li>
                                <div>
                                    To enable screen locking, navigate to Screen
                                    Pinning (also known as Pin Windows) in your
                                    phone’s settings. The location of Screen
                                    Pinning/Pin Windows in settings will vary
                                    between phone model and Android version.
                                    Common locations include:
                                    <ul>
                                        <li>
                                            Settings <Arrow /> Security
                                        </li>
                                        <li>
                                            Settings <Arrow /> Personal{' '}
                                            <Arrow /> Security
                                        </li>
                                        <li>
                                            Settings <Arrow /> Security &
                                            Location
                                        </li>
                                        <li>
                                            Settings <Arrow /> Lock Screen &
                                            Security
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                Turn on Screen Pinning/Pin Windows, and activate
                                the option to require passcode/unlock pattern
                                before unpinning.
                            </li>

                            <li>
                                <div>
                                    <strong>Lock Screen</strong> <br />
                                    Follow the instructions on your screen to
                                    activate Screen Pinning/Pin Windows when
                                    displaying your auto insurance card. Your
                                    phone is now locked your digital wallet
                                    preventing access to personal information,
                                    message notifications and other apps.
                                </div>
                            </li>
                        </Fragment>
                    )}
                </ol>
                <Link to={`/#${menu[language].privacy.id}`}>
                    {privacy[language].back}
                </Link>
            </div>
        </section>
    </Layout>
)
